@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #9EAFDC;
  --secondary-color: #120930;
  --third-color: rgb(191 219 254);
  --gray: #eee;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 14px; /* 16px */
  width: 100%;
}

h1 {
  /* font-size: 1.8rem; */
  --min: 1.8rem;
  --val: 4.5vw;
  --max: 2.3rem;
}

h2 {
  /* font-size: 1.65rem; */
  --min: 1.65rem;
  --val: 4vw;
  --max: 2.15rem;
}

h3 {
  /* font-size: 1.5rem; */
  --min: 1.5rem;
  --val: 3.5vw;
  --max: 2rem;
  text-decoration: underline;
}

h4 {
  /* font-size: 1.35rem; */
  --min: 1.35rem;
  --val: 3vw;
  --max: 1.5rem;
}

p {
  /* font-size: 1.2rem;  */
  --min: 1.2rem;
  --val: 2vw;
  --max: 1.35rem;
  text-wrap: pretty;
}

a {
  text-decoration: underline;
}

h1, h2, h3, h4, p {
  font-size: clamp(var(--min), var(--val), var(--max));
}

.title-container {
  background-color: rgb(191 219 254);
  padding: 2.5rem;
}

.footer-wrapper {
  background-color: var(--third-color);
}

.footer {
  background: var(--third-color);
  width: 100%;
  padding: 1.25rem;
  /* margin: 3rem auto 0 auto; */
  display: flex;
  flex-direction: column;
}

.contact-text p {
  font-size: 1.1rem;
  text-align: right;
}

/* GRIDS */

.grid-container {
  /* width: 90vw; */
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: center;
}

.grid-div {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  gap: 2rem;
  margin: 20px auto;
  max-width: 100%;
  align-items: center;
}

.image {
  overflow: auto;
  vertical-align: bottom;
}

.grid-div img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  aspect-ratio: 4 / 3;
}

.grid-div .text-section {
  padding: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  margin: none;
}

.subheading {
  background-color: var(--third-color);
  width: 100%;
  padding: 0.05rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

.text-section div {
  margin-bottom: 20px;
}

/* ABOUT PAGE */

.about-grid-wrapper .centered-text p {
  --min: 1.4rem;
  --val: 2.6vw;
  --max: 1.7rem;
  font-style: normal;
}

/* FOUNDER SECTION */

.founder-container {
  width: 60%;
  margin: 1.5rem auto;
}

.founder-container .subheading {
  background-color: transparent;
  color: var(--secondary-color);
  width: 100%;
}

.founder-grid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px auto;
  width: 100%;
  height: auto;
}

.founder-grid img {
  object-fit: cover;
  border: 1px var(--secondary-color) solid;
}

.founder-grid .centered-text {
  color: white;
  padding: 10px;
  background-color: var(--secondary-color);
  width: 100%;
}

/* UPCOMING EVENTS SECTION */
.upcoming-container {
  height: max-content;
  background-color: var(--gray);
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upcoming-event {
  border: 1px var(--secondary-color) solid;
  background-color: var(--third-color);
  border-radius: 5px;
  width: 90%;
  height: auto;
  padding: 1rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

/* EVENTS PAGE */

.general-info-container {
  height: max-content;
  width: 90%;
  margin: 1rem auto;
  background-color: white;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.general-info-container h3 {
  color: var(--secondary-color);
}

.programs-container {
  width: 100%;
  display: flex; 
  flex-direction: column;
  justify-content: space-between;
}

.regular-program {
  background-color: var(--third-color);
  margin: 10px auto;
  padding: 25px;
  border-radius: 20px;
  width: 100%;
}

.program {
  margin: 10px auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.program-text {
  margin: 10px auto;
  width: 80vw;
  background-color: var(--gray);
  padding: 1rem;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.program-text h4 {
  text-align: center;
  margin-bottom: 0.5rem;
}
 
/* TABLE */

tr, td, th {
  border: 1px solid rgb(191 219 254);
}

.event-table-container {
  overflow-y: auto;
  height: 15em;
  width: 100%;
  margin: none;
  border: 10px solid rgb(191 219 254);
  border-radius: 0.25rem;
}

.event-table {
  table-layout: auto;
  width: 100%;
}

.event-table-container thead th {
  position: sticky;
  top: 0;
}

.event-table td:first-child {
  width: 20vh;
}

.event-table td:nth-child(2), .event-table td:nth-child(3) {
  width: 10vh;
}

.event-table td:last-child {
  width: 60vh;
}

th, td {
  padding: 0.25rem 1rem;
}

th {
  color: white;
  background-color: var(--secondary-color);
}

td {
  color: var(--secondary-color);
}

/* GENERAL STYLING */

button {
  cursor: pointer;
  width: max-content;
}

ul {
  list-style: disc;
}

em {
  font-weight: bold;
  color: var(--secondary-color);
}

.react-icons {
  vertical-align: middle;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.shadow {
  box-shadow: 0 4px 6px -1px rgb(148, 145, 145), 0 2px 4px -2px rgb(148, 145, 145);
}

.centered-text {
  width: 90%;
  height: min-content;
  margin: 0 auto;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline var(--secondary-color);
}

.ellipsis {
  text-overflow: ellipsis;
}

.bold {
  font-weight: bold;
}

.subtitle {
  font-weight: 400;
}

.transparent {
  background-color: transparent;
}

.title-banner {
  width: 100%;
  border-radius: 50px;
  padding: 1.25rem;
  margin: 0 auto;
  background-color: white;
  font-weight: 700;
  text-align: center;
}

/* HEADER, NAVIGATION BAR */

header {
  width: 100%;
  height: auto;
  border: 0;
  background-color: var(--third-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.logo {
  font-weight: 700;
  font-size: 1.25rem;
  padding: 0;
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.15rem;
  font-weight: 400;
  margin: 0 1rem;
}

.linked-button {
  display: inline-block;
  background-color: var(--third-color);
  border-radius: 9999px;
  padding: 0.5rem 1rem;
}

nav .linked-button {
  border-radius: 0;
}

.linked-button:hover {
  background-color: var(--secondary-color);
  color: white;
}

.image {
  background-color: var(--gray);
  color: black;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

/* CALENDAR-RELATED CSS */

.day-block {
  border: 1.5px rgb(229, 231, 235) solid;
  /* border-radius: 0.375rem; */
  padding: 0.25rem;
  min-width: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.day-block:hover {
  cursor: pointer;
  background-color: var(--third-color);
}

.day-block-today {
  background-color: rgb(229, 231, 235);
  color: var(--secondary-color);
}

.day-block-active {
  border-color: var(--secondary-color);
  border-width: 2px;
  background-color: var(--third-color);
}

.day-number {
  margin: 5 auto;
  padding: none;
  text-align: left;
  font-size: 1rem;
  font-weight: bold;
}

/* each event in a day */
.day-event-block {
  background-color: var(--secondary-color);
  color: white;
  margin: 0.25rem auto;
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: 80%;
}

.day-event-content {
  font-size: 0.85rem;
}

.calendar-app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
}

.calendar {
  width: 95%;
  margin: 0 auto;
  padding: 1rem;
}

.calendar-grid {
  display: grid;
  gap: 0;
  width: 100%;
  overflow-y: auto;
  height: 30rem;
  margin: 1rem auto;
}

.time-control {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0.5rem auto;
  margin-bottom: 1.5rem;
  width: 95%;
}

.time-control button {
  background-color: transparent;
  text-align: center;
  font-weight: 700;
  font-size: 1.25rem;
  border: 1px solid var(--secondary-color);
  border-radius: 0.25rem;
  padding: 0.5rem;
}

.time-control button:hover {
  background-color: var(--third-color);
}

.days-of-week {
  display: none;
  margin-top: 1.25rem;
}

.event-header-container {
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.event-filter-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.event-filter-bar {
  max-width: 100%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.25rem;
  margin: 0.5rem 0;
  background-color: rgb(229 231 235);
  border: 1px solid var(--secondary-color);
  border-radius: 0.25rem;
  border-width: 2px;
}

.event-filter-option {
  margin: 0.25rem;
  padding: 0.4rem;
  text-align: center;
}

.event-filter-option p {
  --min: 1rem;
  --val: 1vw;
  --max: 1.2rem;
}

.event-table-container, .excel-table-container {
  margin-top: 1rem;
  height: auto;
  max-height: 20vh;
  overflow-y: auto;
  border: 1px solid var(--third-color);
}

.event-table thead tr {
  background-color: var(--secondary-color);
  color: white;
  text-align: left;
}

/* .description-td {
  max-width: 100px;
} */

.excel-input-container {
  width: 75%;
  margin: 0 auto;
}

.instructions {
  width: 80%;
  background-color: var(--gray);
  padding: 1.5rem;
  margin: 0.5rem auto;
}

/* LOGIN PAGE */
.login-container {
  background-color: var(--third-color);
}

.login-form {
  background-color: white;
  border: 2px var(--third-color) solid;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  width: 33vw;
  margin: 5rem auto;
  padding: 1.5rem;
}

.input-box {
  margin: 1.25rem auto;
  /* border: 1px var(--secondary-color) solid; */
  border-bottom: 2px var(--secondary-color) solid;
  width: 100%;
  padding: 0.5rem 0.75rem;
  color: black;
  opacity: 1;
  line-height: 1.25;
}

.input-box:focus, .submit-button:focus {
  border-color: black;
}

.submit-button {
  background: var(--third-color);
  font-weight: 600;
  margin: 1rem auto;
  padding: 0.5rem 1rem;
  border: 1.5px var(--secondary-color) solid;
  border-radius: 0.25rem;
  text-align: center;
  width: 100%;
}

.submit-button:hover {
  background-color: var(--gray);
}

/* MEDIA QUERIES FOR LARGE SCREENS */

@media screen and (min-width: 800px) {
  html {
    font-size: 16px;
  }
}

@media screen and (min-width: 700px) {
  header {
    flex-direction: row;
  }

  .grid-container {
    width: 95vw;
    max-width: 100%;
    margin: 1rem auto;
  }
  
  .grid-div {
    grid-template-columns: repeat(2, 1fr);
    /* max-width: 100%; */
  }

  .general-info-container {
    margin: 1rem auto;
  }

  .founder-container {
    width: 30%;
  }

  .programs-container {
    flex-direction: row;
  }

  .horizontal-container {
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }

  .program {
    flex-direction: column;
    justify-content: space-evenly;
  }

  .regular-program {
    width: 32%;
  }

  .footer {
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
  }

  .contact-text p {
    text-align: left;
  }

  .calendar {
    width: 90%;
    margin: 1rem auto;
  }

  .calendar-grid, .days-of-week {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    gap: 0;
    width: 100%;
    height: 100%;
  }
  
  .calendar-grid {
    grid-template-rows: repeat(5, minmax(0, 1fr));
    height: 100%;
  }

  .event-filter-bar {
    flex-direction: row;
  }

  .time-control button {
    padding: 0.5rem 1.5rem;
  }

  .event-table td:first-child {
    width: 20%;
  }
  
  .event-table td:nth-child(2), .event-table td:nth-child(3) {
    width: 10%;
  }
  
  .event-table td:last-child {
    width: 60%;
  }

  .calendar-app-container {
    margin: 2rem auto;
  }

  .date-td {
    white-space: nowrap;
  }

  .day-block {
    flex-direction: column;
  }

  .day-event-block {
    width: 100%;
  }

  .title-banner {
    width: 35%;
  }

  .home-page-wrapper .title-banner {
    width: 100%;
  }
}
